export const themes = (mode: any) => (mode === 'light'
? {
  background: {
    default: "#F2F2F2",
  },
  text: {
  },
}
: {
  background: {
    default: '#0A1929',
  },
})