import Project from "./types/Project";

export const projects: Project[] = [
  {
    id: 'awscloudpractitioner',
    name: "projects.awscloudpractitioner.name",
    date: "projects.awscloudpractitioner.date",
    description: "projects.awscloudpractitioner.description",
    imageUrl: "https://images.credly.com/size/680x680/images/00634f82-b07f-4bbd-a6bb-53de397fc3a6/image.png",
    link: "https://www.credly.com/badges/746cd78e-5847-45dd-b138-198486fc0f5f",
  },
  {
    id: 'gorlami',
    name: "projects.gorlami.name",
    date: "projects.gorlami.date",
    description: "projects.gorlami.description",
    imageUrl: "https://miro.medium.com/v2/resize:fit:996/0*cHwHgiJcCMhQ9ezp.gif",
    link: "https://medium.com/@tete5423/bdff4f3ee047",
  },
  {
    id: 'plexMediaServer',
    name: "projects.plexMediaServer.name",
    date: "projects.plexMediaServer.date",
    description: "projects.plexMediaServer.description",
    imageUrl: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*GsqI4vM5akhcVLgsq_8Fyw.jpeg",
    link: "https://medium.com/@tete5423/3c9b84a9dda5",
  },
  {
    id: 'gdgCampinas',
    name: "projects.gdgCampinas.name",
    date: "projects.gdgCampinas.date",
    description: "projects.gdgCampinas.description",
    imageUrl: "./projects-page/gdgonline.gif",
    link: "https://www.youtube.com/watch?v=0kKge3K0ZwI",
  },
  {
    id: 'aqueduct',
    name: "projects.aqueduct.name",
    date: "projects.aqueduct.date",
    description: "projects.aqueduct.description",
    imageUrl: "./projects-page/aqueduct.png",
    link: "https://medium.com/flutter-comunidade-br/criando-uma-aplica%C3%A7%C3%A3o-web-com-dart-parte-1-aqueduct-b%C3%A1sico-conex%C3%A3o-com-banco-e-autentica%C3%A7%C3%A3o-jwt-bd3afad3ae1a",
  },
  {
    id: 'angularDart',
    name: "projects.angularDart.name",
    date: "projects.angularDart.date",
    description: "projects.angularDart.description",
    imageUrl: "./projects-page/angulardart.png",
    link: "https://medium.com/flutter-comunidade-br/criando-uma-aplica%C3%A7%C3%A3o-web-com-dart-parte-2-angulardart-b%C3%A1sico-roteamento-e-chamadas-http-9a7e7187a477",
  },
  {
    id: 'badgeJavaInstructor',
    name: "projects.badgeJavaInstructor.name",
    date: "projects.badgeJavaInstructor.date",
    description: "projects.badgeJavaInstructor.description",
    imageUrl: "./projects-page/educator.png",
    link: "https://www.credly.com/badges/d44e38f6-81cb-4c23-831d-4817b5759a28",
  },
  {
    id: 'flutterAuth',
    name: "projects.flutterAuth.name",
    date: "projects.flutterAuth.date",
    description: "projects.flutterAuth.description",
    imageUrl: "./projects-page/webview.png",
    link: "https://www.linkedin.com/posts/marco-antonio-goncalves_flutter-webview-mobile-activity-6609090891527274496-ESnq/",
  },
  {
    id: 'fatecPortasAbertas',
    name: "projects.fatecPortasAbertas.name",
    date: "projects.fatecPortasAbertas.date",
    description: "projects.fatecPortasAbertas.description",
    imageUrl: "./projects-page/fpa.png",
    link: "https://www.linkedin.com/posts/murilohenriquegomes_hacking-kalilinux-activity-6595643833621467136-Xtak/",
  },
  {
    id: 'webFrontEndEssentials',
    name: "projects.webFrontEndEssentials.name",
    date: "projects.webFrontEndEssentials.date",
    description: "projects.webFrontEndEssentials.description",
    imageUrl: "./projects-page/udacity.png",
    link: "https://graduation.udacity.com/confirm/ZRDXRND5",
  },
] 